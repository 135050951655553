<template>
  <a-card style="margin-top: 24px" :bordered="false">
    <!--    <a-descriptions size="small" :column="4">-->
    <!--      <template #title>-->
    <!--        <exclamation-circle-outlined style="color: #ff0000" v-if="!invoiceCreate" />-->
    <!--        <check-circle-outlined style="color: #00DD00" v-if="invoiceCreate" />-->
    <!--        <span style="font-weight: bolder; margin-left: 20px">-->
    <!--          工资单【-->
    <!--          <span style="color: #00DD00">-->
    <!--            <check-circle-outlined style="color: #00DD00" />-->
    <!--            {{ invoice_obj.payslip_num }}-->
    <!--          </span>-->
    <!--          <span style="color: #f5222d">-->
    <!--            <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />-->
    <!--            {{ invoice_obj.remittance_list.length - invoice_obj.payslip_num }}-->
    <!--          </span>-->
    <!--          】-->
    <!--        </span>-->
    <!--        <span style="font-weight: bolder; margin-left: 20px">-->
    <!--          生成PDF【-->
    <!--          <span style="color: #00DD00">-->
    <!--            <check-circle-outlined style="color: #00DD00" />-->
    <!--            {{ invoice_obj.pdf_num }}-->
    <!--          </span>-->
    <!--          <span style="color: #f5222d">-->
    <!--            <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />-->
    <!--            {{ invoice_obj.remittance_list.length - invoice_obj.pdf_num }}-->
    <!--          </span>-->
    <!--          】-->
    <!--        </span>-->
    <!--        <span style="font-weight: bolder; margin-left: 20px">-->
    <!--          已发送【-->
    <!--          <span style="color: #00DD00">-->
    <!--            <check-circle-outlined style="color: #00DD00" />-->
    <!--            {{ invoice_obj.sted_num }}-->
    <!--          </span>-->
    <!--          <span style="color: #f5222d">-->
    <!--            <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />-->
    <!--            {{ invoice_obj.remittance_list.length - invoice_obj.sted_num }}-->
    <!--          </span>-->
    <!--          】-->
    <!--        </span>-->
    <!--      </template>-->
    <!--    </a-descriptions>-->
    <a-checkbox v-model:checked="checked_all" @click="all_checked()">{{ t('全选') }}</a-checkbox>
    <template v-for="obj in invoice_obj.remittance_list" :key="obj.index">
      <a-card type="inner">
        <a-descriptions size="small" :column="4">
          <template #title>
            <a-checkbox v-model:checked="obj.checked">{{ t('选中') }}</a-checkbox>
            <!--            <exclamation-circle-outlined style="color: #ff0000" v-if="!obj.is_remittance" />-->
            <!--            <check-circle-outlined style="color: #00DD00" v-if="obj.is_remittance" />-->
            {{ obj.adviser }}
            &nbsp;&nbsp;&nbsp; {{ t('金额') }}: {{ obj.amount }} &nbsp;&nbsp;&nbsp;
            {{ t('请款单') }}:
            {{ obj.invoice_no }}
            &nbsp;&nbsp;&nbsp; 服务月份:
            {{ getMonth(obj.service_month) }}
          </template>
        </a-descriptions>
        <a-card type="inner">
          <template #title>
            <span v-if="obj.invoice_list.length >= 1">{{ t('请款单列表') }}</span>
            <span v-if="obj.invoice_list.length < 1" style="color: #f5222d">
              {{ t('未找到相关请款单') }}
            </span>
            <a-button
              style="margin-left: 15px"
              size="small"
              :disabled="obj.is_invoice || obj.is_checked"
              type="primary"
              @click="
                () => {
                  modalRef.visible = true;
                  loading = true;
                  modalRef.index = obj.index;
                }
              "
            >
              {{ t('查找请款单') }}
            </a-button>
          </template>
          <template v-for="item in obj.invoice_list" :key="item.id">
            <a-descriptions size="small" :column="3">
              <template #title>
                <span v-if="item.is_payslip">
                  【
                  <span style="font-weight: bolder">
                    {{
                      item.status === 1
                        ? t('新创建')
                        : item.status === 2
                        ? t('已发送')
                        : item.status === 3
                        ? t('已确认')
                        : item.status === 4
                        ? t('生成PDF')
                        : ''
                    }}
                  </span>
                  】
                </span>
                {{ item.invoice_no }}
                <br v-if="item.is_create" />
                <a-button
                  style="margin-left: 15px"
                  size="small"
                  :disabled="item.is_create || item.is_checked"
                  :loading="item.loading1"
                  type="primary"
                  @click="create_remittance_pro(obj, item)"
                >
                  {{ t('创建汇款凭证项') }}
                </a-button>
                <a-button
                  v-if="!(item.is_create || item.is_checked)"
                  style="margin-left: 15px"
                  size="small"
                  type="danger"
                  @click="del_invoice(obj, item)"
                >
                  {{ t('删除') }}
                </a-button>
                <a-button
                  v-if="item.is_create"
                  style="margin-left: 15px"
                  size="small"
                  :disabled="item.status"
                  type="primary"
                  @click="btn_create_payslip.cb(item)"
                >
                  {{ t('生成工资单') }}
                </a-button>
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  type="primary"
                  @click="btn_update_payslip.cb(item)"
                >
                  {{ t('修改') }}
                </a-button>
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  type="primary"
                  @click="btn_manage_payslip.cb(item)"
                >
                  {{ t('管理费') }}
                </a-button>
                <!--                <a-button-->
                <!--                  v-if="item.is_payslip"-->
                <!--                  style="margin-left: 15px"-->
                <!--                  size="small"-->
                <!--                  :disabled="item.is_payment"-->
                <!--                  type="primary"-->
                <!--                  @click="btn_create_bank.cb(item)"-->
                <!--                >-->
                <!--                  付款-->
                <!--                </a-button>-->
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  :disabled="item.status !== 1"
                  type="primary"
                  @click="btn_create_pdf.cb(item, t)"
                >
                  {{ t('生成PDF') }}
                </a-button>
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  :disabled="item.status !== 4"
                  type="primary"
                  @click="btn_create_send.cb(item, t)"
                >
                  {{ t('发送邮件') }}
                </a-button>
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  type="primary"
                  @click="btn_contract.cb(item)"
                >
                  {{ t('合同信息') }}
                </a-button>
                <a-button
                  v-if="item.is_payslip"
                  style="margin-left: 15px"
                  size="small"
                  :loading="item.is_refresh_loading"
                  type="primary"
                  title="primary"
                  @click="btn_refresh_payslip.cb(item)"
                >
                  {{ t('刷新') }}
                </a-button>
              </template>
              <a-descriptions-item :label="t('顾问')">
                {{ item.adviser__full_name }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('工号')">
                {{ item.adviser__employee_number }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('请款起始日期')">
                {{ getDate(item.invoice_start_date) }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('请款截止日期')">
                {{ getDate(item.invoice_end_date) }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('服务月份')">
                {{ getMonth(item.service_month) }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('请款单总额')">
                <span :style="item.color">{{ item.total_amount }}</span>
              </a-descriptions-item>
              <a-descriptions-item :label="t('汇款凭证项金额')">
                {{ item.remittance__amount }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('是否已关联汇款凭证项')">
                {{ item.remittance_id ? t('是') : t('否') }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('待办未完成')">
                <a-button size="small" type="link" @click="goto(item)">
                  {{ item.to_do_num }}
                </a-button>
              </a-descriptions-item>
              <a-descriptions-item :label="t('汇款凭证名称')">
                {{ item.remittance_name }}
              </a-descriptions-item>
              <a-descriptions-item :label="t('请款单状态')">
                {{
                  item?.invoice_status == 1
                    ? t('新创建')
                    : item?.invoice_status == 2
                    ? t('已发送')
                    : item?.invoice_status == 3
                    ? t('已收到请款')
                    : item?.invoice_status == 4
                    ? t('已作废')
                    : item?.invoice_status == 5
                    ? t('生成工资单')
                    : item?.invoice_status == 6
                    ? t('生成PDF')
                    : item?.invoice_status == 7
                    ? t('垫付')
                    : ''
                }}
              </a-descriptions-item>
            </a-descriptions>
            <a-divider style="margin: 16px 0" />
            <a-descriptions size="small" :column="3">
              <a-descriptions-item v-if="item.is_payslip" :label="t('顾问')">
                {{ item.adviser__full_name }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('付款状态')">
                <a-tag v-if="item.payment_status == 2" color="green">{{ t('已付款') }}</a-tag>
                <a-tag v-if="item.payment_status == 0" color="red">{{ t('未收到款项') }}</a-tag>
                <a-tag v-if="item.payment_status == 1" color="red">{{ t('收到款项') }}</a-tag>
                <a-tag v-if="item.payment_status == 3" color="red">{{ t('垫付') }}</a-tag>
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('邮件发送状态')">
                <span
                  v-if="item.email_record__status === 2"
                  style="color: #f5222d; font-weight: bolder"
                >
                  {{ t('发送失败') }}
                </span>
                <span
                  v-if="item.email_record__status === 1 || item.email_record__status === 2"
                  style="color: forestgreen; font-weight: bolder"
                >
                  {{
                    item.email_record__status === 1
                      ? t('发送成功')
                      : item.email_record__status === 3
                      ? t('已发送')
                      : ''
                  }}
                </span>
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('邮件发送日志')">
                {{ item.email_record__log }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('邮件发送时间')">
                {{ getDateTime(item.email_record__create_time) }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" label="PDF">
                <a-button
                  v-if="item.files_json && item.files_json.file_url && item.files_json.name"
                  size="small"
                  type="link"
                  @click="open_pdf(item.files_json.file_url)"
                >
                  {{ item.files_json?.name }}
                </a-button>
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" label="Excel">
                <a-button
                  v-if="
                    item.excel_files_json &&
                    item.excel_files_json.file_url &&
                    item.excel_files_json.name
                  "
                  size="small"
                  type="link"
                  @click="open_pdf(item.excel_files_json.file_url)"
                >
                  {{ item.excel_files_json?.name }}
                </a-button>
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('收到日期')">
                {{ getDate(item.date) }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('备注')">
                {{ item.remark }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('付款方式')">
                {{ item.payment_method__name }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('银行流水')">
                {{ item.transaction__name }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('推荐费')">
                {{ item.referral_fees }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('管理费')">
                {{ item.management_fee }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('银行转账费')">
                {{ item.bank_charge_cost }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('保险费')">
                {{ item.insurance_fee }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('总金额')">
                {{ item.invoice__total_amount }}
              </a-descriptions-item>
              <a-descriptions-item v-if="item.is_payslip" :label="t('合同到期提示')">
                {{ item.tip }}
              </a-descriptions-item>

              <template v-if="item.is_payslip" v-for="item in item.customfield_list">
                <a-descriptions-item ::label="item.custom_field_name">
                  {{ item.custom_field_value }}
                </a-descriptions-item>
              </template>
            </a-descriptions>
          </template>
        </a-card>
      </a-card>
    </template>
  </a-card>

  <a-card v-show="dataSource.length > 0">
    <div class="ant-pro-table-list-toolbar">
      <div class="ant-pro-table-list-toolbar-container">
        <div class="ant-pro-table-list-toolbar-left">
          <div class="ant-pro-table-list-toolbar-title">
            {{ t('工资单展示') }}
            <a-button
              size="small"
              style="margin-right: 3px; margin-left: 15px"
              @click="batch_create_pdf"
              type="primary"
            >
              {{ t('批量生成PDF') }}
            </a-button>
            <a-button
              size="small"
              style="margin-right: 3px"
              @click="batch_create_email"
              type="primary"
            >
              {{ t('批量发送邮件') }}
            </a-button>
            <a-button size="small" style="margin-right: 3px" @click="refresh" type="primary">
              {{ t('刷新') }}
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <a-table
      bordered="true"
      :scroll="{ x: 200, y: 700 }"
      size="small"
      :columns="Columns"
      :data-source="dataSource"
      :pagination="false"
    >
      <template
        v-for="item in slotList1"
        v-slot:[item.slotName]="{ index, text, record }"
        :key="item.slotName"
      >
        <span v-if="item.slotName === 'index'">
          {{ index + 1 + state.pageSize * (state.current - 1) }}
        </span>
        <span v-if="item.type === 'custom'">
          {{ item['field_' + text] }}
        </span>
        <span v-if="item.type === 'custom1'">
          {{ item.content(record) }}
        </span>
        <span v-if="item.type === 'datetime'">
          {{ getDateTime(text) }}
        </span>
        <span v-if="item.type === 'date'">
          {{ getDate(text) }}
        </span>
        <span v-if="item.type === 'month'">
          {{ getMonth(text) }}
        </span>
        <span v-if="item.type === 'time'">
          {{ getTime(text) }}
        </span>
        <span v-if="item.type === 'percent'">{{ text }}%</span>
        <span v-if="item.type === 'toFixed2'">
          {{ text.toFixed(2) }}
        </span>
        <span v-if="item.type === 'money'">
          {{ $numeral.money(text) }}
        </span>
        <span v-if="item.type === 'red'" :style="item.cb(record) ? 'color: #f5222d' : ''">
          {{ text }}
        </span>
        <span v-if="item.type === 'color'" :style="item.cb(record) ? item.cb(record) : ''">
          <span v-if="item.content">
            {{ item.content(record) }}
          </span>
          <span v-else>
            {{ text }}
          </span>
        </span>
        <a-button
          style="padding-left: 1px"
          v-if="item.type === 'click'"
          type="link"
          @click="item.cb(record)"
          :title="item.content(record) ? item.content(record) : text"
        >
          <span
            v-if="item.content"
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: inline-block;
              width: 180px;
            "
          >
            {{ item.content(record) }}
          </span>
          <span
            v-else
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: inline-block;
              width: 180px;
            "
          >
            {{ text }}
          </span>
        </a-button>
        <template v-if="item.type === 'clickList'">
          <template v-for="file in text" :key="file.file_id">
            <a-button style="padding: 1px" type="link" @click="item.cb(file)" :title="file.name">
              <a-tooltip>
                <span
                  style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: inline-block;
                    width: 150px;
                  "
                >
                  {{ file.name }}
                </span>
              </a-tooltip>
            </a-button>
            <delete-outlined @click="item.del(record, text, file)" style="color: #ff0000" />
          </template>
        </template>
        <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
        <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
        <template v-if="item.type === 'list'">
          <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
            {{ obj.name }}
          </a-tag>
        </template>
      </template>
      <template #action="{ record }">
        <a-dropdown-button @click.stop="preview_pay(record)" size="small">
          {{ t('预览') }}
          <template #overlay>
            <a-menu>
              <a-menu-item key="update" @click="update_pay(record)">{{ t('修改') }}</a-menu-item>
              <a-menu-item key="update" @click="mangefee_pay(record)">
                {{ t('管理费') }}
              </a-menu-item>
              <a-menu-item key="update" v-if="record.is_active" @click="del_pay(record)">
                {{ t('删除') }}
              </a-menu-item>
              <a-menu-item key="update" v-if="!record.is_active" @click="recover_pay(record)">
                {{ t('恢复') }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown-button>
      </template>
    </a-table>
  </a-card>
  <a-card style="margin-top: 24px" :bordered="false">
    <a-button
      style="margin-left: 15px"
      size="small"
      type="primary"
      :disabled="checkDisabledFun()"
      @click="create_remittance_list"
    >
      {{ t('批量创建汇款凭证项/工资单') }}
    </a-button>
    <a-button style="margin-left: 15px" size="small" type="primary" @click="create_pdf">
      {{ t('批量生成PDF') }}
    </a-button>
    <a-button style="margin-left: 15px" size="small" type="primary" @click="send__email">
      {{ t('批量发送邮件') }}
    </a-button>
  </a-card>
  <a-modal
    :title="t('查询请款单')"
    width="900px"
    :visible="modalRef.visible"
    :maskClosable="false"
    :confirmLoading="loading"
    centered
    @ok="handleSubmit(modalRef.index)"
    @cancel="
      () => {
        modalRef.visible = false;
      }
    "
  >
    <search-render
      v-if="searchConfig"
      :form="searchConfig"
      :model="search_modelRef"
      :options="searchConfigOptions"
      :validateInfos="searchValidateInfos"
      :resetFields="searchResetFields"
      @search="search"
    />
    <a-table
      bordered="true"
      size="small"
      :loading="state.loading"
      :scroll="{ y: 400, x: columnState.tableWidth }"
      :row-selection="rowSelection"
      :columns="dynamicColumns"
      row-key="id"
      :data-source="state.dataSource"
      :pagination="{
        current: state.current,
        pageSize: state.pageSize,
        total: state.total,
      }"
      @change="handleTableChange"
    >
      <template
        v-for="item in slotList"
        v-slot:[item.slotName]="{ index, text }"
        :key="item.slotName"
      >
        <span v-if="item.slotName === 'index'">
          {{ index + 1 + state.pageSize * (state.current - 1) }}
        </span>
        <span v-if="item.type === 'custom'">
          {{ item['field_' + text] }}
        </span>
        <span v-if="item.type === 'datetime'">
          {{ getDateTime(text) }}
        </span>
        <span v-if="item.type === 'date'">
          {{ getDate(text) }}
        </span>
        <span v-if="item.type === 'time'">
          {{ getTime(text) }}
        </span>
        <a-tag color="green" v-if="text && item.type === 'boolean'">{{ item.name1 }}</a-tag>
        <a-tag color="red" v-if="!text && item.type === 'boolean'">{{ item.name2 }}</a-tag>
        <template v-if="item.type === 'list'">
          <a-tag color="#87d068" v-for="obj in text" :key="obj.id" style="margin-bottom: 1px">
            {{ obj.name }}
          </a-tag>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>

<script lang="ts">
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons-vue';
import { createVNode, defineComponent, onBeforeUnmount, reactive, ref, watchEffect } from 'vue';
import {
  bulk_create_payslip,
  bulk_create_payslip_pdf,
  bulk_create_remittance,
  bulk_send_payslip_by_email,
  create_remittance,
  delete_payslip,
  modify_payslip_mangefee,
  query_payslip_list,
  recover_payslip,
} from '@/api/cerp-model';
import { useStore } from 'vuex';
import { message, Modal, notification } from 'ant-design-vue';
import getFormConfig from '@/components/FormRender/RemoteModal/index';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useFetchData } from '@/utils/hooks/useFetchData';
import { useForm } from 'ant-design-vue/es/form';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import {
  btn_contract,
  btn_create_payslip,
  btn_create_pdf,
  btn_create_send,
  btn_manage_payslip,
  btn_refresh_payslip,
  btn_update_payslip,
  clear,
} from '@/components/show-remittanceset/index1.ts';
import modalBox from '@/components/form-modal/modal-tools';
import router from '@/router';
import { useI18n } from 'vue-i18n';

type Key = ColumnProps['key'];

interface DataType {
  id: number;
  key: Key;
  name: string;
}

export default defineComponent({
  name: 'ShowRemittanceSet',
  components: { UploadOutlined, ExclamationCircleOutlined, CheckCircleOutlined },
  props: {
    title: {
      type: String,
      required: true,
    },
    invoiceObj: {
      type: Object,
      required: true,
    },
    invoiceCreate: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const loading = ref(false);
    const checked_all = ref(false);
    const btn_loading = ref(false);
    const payslip_loading = ref(false);
    let payslip__ids: any = [];
    // const payslip_good = ref(false);
    const dataSource: any = ref([]);
    onBeforeUnmount(() => {
      clear();
    });
    const invoice_obj: any = reactive({
      remittanceset_id: null,
      description: '',
      ...props.invoiceObj,
    });
    const modalRef = reactive<{
      selectedRowsKey: Array<any>; // 选中行key数组
      selectedData: Array<any>; // 选中行数据
      visible: boolean;
      index: number;
    }>({
      selectedRowsKey: [],
      selectedData: [],
      visible: false,
      index: 0,
    });
    const { searchConfig, searchConfigOptions, getData, columns, slotList } = getFormConfig(
      'cerp-invoice',
    );
    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      columns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
    );
    const search_modelRef = reactive({
      ...searchConfig.model,
      org_id: current_org.id,
      is_active: true,
    });
    const { validateInfos: searchValidateInfos, resetFields: searchResetFields } = useForm(
      search_modelRef,
      {},
    );
    const { reload, setPageInfo, context: state } = useFetchData(getData, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };
    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      setPageInfo({
        current: current ? current : state.current,
        pageSize: pageSize ? pageSize : state.pageSize,
        ...filters,
        ...search_modelRef,
      });
      reload();
    };

    const Columns = [
      {
        title: t('序号'),
        dataIndex: 'index',
        key: 'index',
        width: 50,
        slots: { customRender: 'index' },
        fixed: 'left',
      },
      {
        title: t('顾问'),
        width: 150,
        dataIndex: 'adviser__full_name',
        key: 'adviser__full_name',
        checked: true,
        fixed: 'left',
      },
      {
        title: t('请款单'),
        width: 160,
        dataIndex: 'invoice__invoice_no',
        key: 'invoice__invoice_no',
        checked: true,
        fixed: 'left',
      },
      {
        title: t('工号'),
        width: 80,
        dataIndex: 'adviser__employee_number',
        key: 'adviser__employee_number',
        checked: true,
      },
      {
        title: t('是否可用'),
        width: 150,
        dataIndex: 'is_active',
        key: 'is_active',
        checked: true,
        slots: { customRender: 'is_active' },
      },
      {
        title: t('状态'),
        width: 100,
        dataIndex: 'status',
        key: 'status',
        checked: true,
        slots: { customRender: 'status' },
      },
      {
        title: t('pdf生成状态'),
        width: 100,
        dataIndex: 'pdf_status',
        key: 'pdf_status',
        checked: true,
        slots: { customRender: 'pdf_status' },
      },
      {
        title: t('pdf生成日志'),
        width: 100,
        dataIndex: 'pdf_log',
        key: 'pdf_log',
        checked: true,
      },
      {
        title: t('付款状态'),
        width: 120,
        dataIndex: 'payment_status',
        key: 'payment_status',
        checked: true,
        slots: { customRender: 'payment_status' },
      },
      {
        title: t('邮件发送状态'),
        width: 120,
        dataIndex: 'email_record__status',
        key: 'email_record__status',
        checked: true,
        slots: { customRender: 'email_record__status' },
      },
      {
        title: t('邮件发送日志'),
        width: 120,
        dataIndex: 'email_record__log',
        key: 'email_record__log',
        checked: true,
      },
      {
        title: t('邮件发送时间'),
        width: 120,
        dataIndex: 'email_record__create_time',
        key: 'email_record__create_time',
        checked: true,
        slots: { customRender: 'datatime' },
      },
      {
        title: 'PDF',
        width: 200,
        dataIndex: 'files_json',
        key: 'files_json',
        checked: true,
        slots: { customRender: 'files_json' },
      },
      {
        title: 'Excel',
        width: 200,
        dataIndex: 'excel_files_json',
        key: 'excel_files_json',
        checked: true,
        slots: { customRender: 'excel_files_json' },
      },
      {
        title: t('请款类型'),
        width: 100,
        dataIndex: 'invoice__invoice_type',
        key: 'invoice__invoice_type',
        checked: true,
        slots: { customRender: 'invoice_type' },
      },
      {
        title: t('收到日期'),
        width: 150,
        dataIndex: 'date',
        key: 'date',
        checked: true,
        slots: { customRender: 'date' },
      },
      {
        title: t('备注'),
        width: 150,
        dataIndex: 'remark',
        key: 'remark',
        checked: true,
      },
      {
        title: t('银行流水'),
        width: 150,
        dataIndex: 'transaction__name',
        key: 'transaction__name',
        checked: true,
      },
      {
        title: t('推荐费'),
        width: 150,
        dataIndex: 'referral_fees',
        key: 'referral_fees',
        checked: true,
        slots: { customRender: 'money' },
      },
      {
        title: t('管理费'),
        width: 150,
        dataIndex: 'management_fee',
        key: 'management_fee',
        checked: true,
        slots: { customRender: 'money' },
      },
      {
        title: t('银行转账费'),
        width: 150,
        dataIndex: 'bank_charge_cost',
        key: 'bank_charge_cost',
        checked: true,
        slots: { customRender: 'money' },
      },
      {
        title: t('保险费'),
        width: 150,
        dataIndex: 'insurance_fee',
        key: 'insurance_fee',
        checked: true,
        slots: { customRender: 'money' },
      },
      {
        title: t('总金额'),
        width: 150,
        dataIndex: 'total_amount',
        key: 'total_amount',
        checked: true,
        slots: { customRender: 'money' },
      },
      {
        title: t('币种'),
        width: 150,
        dataIndex: 'transaction__currency__currency',
        key: 'transaction__currency__currency',
        checked: true,
      },
      {
        title: t('付款币种'),
        width: 150,
        dataIndex: 'pay_currency__currency',
        key: 'pay_currency__currency',
        checked: true,
      },
      {
        title: t('CC付款账户'),
        width: 150,
        dataIndex: 'cc_bank_account__account_flag',
        key: 'cc_bank_account__account_flag',
        checked: true,
      },
      {
        title: t('创建时间'),
        width: 150,
        dataIndex: 'create_time',
        key: 'create_time',
        checked: true,
        slots: { customRender: 'datetime' },
      },
      {
        title: t('更新时间'),
        width: 150,
        dataIndex: 'update_time',
        key: 'update_time',
        checked: true,
        slots: { customRender: 'datetime' },
      },
      {
        title: t('是否有效'),
        // width: 150,
        dataIndex: 'is_valid',
        key: 'is_valid',
        checked: true,
        slots: { customRender: 'is_valid' },
      },
      {
        title: t('操作'),
        width: 180,
        dataIndex: 'action',
        key: 'action',
        checked: false,
        slots: { customRender: 'action' },
        fixed: 'right',
      },
    ];
    const slotList1 = [
      {
        slotName: 'index', // 槽名
      },
      // {
      //   slotName: 'status',
      //   type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
      //   name1: '正常', // text字段有或true 展示
      //   name2: '禁用', // text字段无或false 展示
      // },
      {
        slotName: 'bool',
        type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
        name1: t('是'), // text字段有或true 展示
        name2: t('否'), // text字段无或false 展示
      },
      {
        slotName: 'is_bank_account_intact',
        type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
        name1: t('是'), // text字段有或true 展示
        name2: t('否'), // text字段无或false 展示
      },
      {
        slotName: 'is_active',
        type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
        name1: t('可用'), // text字段有或true 展示
        name2: t('禁用'), // text字段无或false 展示
      },
      {
        slotName: 'time',
        type: 'time', // 类型：'time' - text字段为时间
      },
      {
        slotName: 'money',
        type: 'money', // 类型：'number' - text字段为钱
      },
      {
        slotName: 'month',
        type: 'month', // 类型：'time' - text字段为时间
      },
      {
        slotName: 'datetime',
        type: 'datetime', // 类型：'time' - text字段为时间
      },
      {
        slotName: 'date',
        type: 'date', // 类型：'date' - text字段为日期
      },
      {
        slotName: 'full_date',
        type: 'full_date', // 类型：'date' - text字段为日期
      },
      {
        slotName: 'percent',
        type: 'percent', // 类型：'date' - text字段为日期
      },
      {
        slotName: 'toFixed2',
        type: 'toFixed2', // 类型：'date' - text字段为日期
      },
      {
        slotName: 'list',
        type: 'list', // 类型：'list' - text字段为数组
      },
      {
        type: 'custom',
        slotName: 'insurance_type',
        field_0: t('需要'),
        field_1: t('不需要–顾问在本地'),
        field_2: t('不需要–顾问自己有保单'),
        field_3: t('不需要–顾问公司保险'),
      },
      {
        type: 'custom',
        slotName: 'is_valid',
        field_true: t('有效'),
        field_false: t('无效'),
      },
      {
        type: 'custom',
        slotName: 'payment_status',
        field_0: t('未收到款项'),
        field_1: t('收到款项'),
        field_2: t('已付款'),
        field_3: t('垫付'),
      },
      {
        type: 'custom',
        slotName: 'pdf_status',
        field_0: t('未开始'),
        field_1: t('正在生成'),
        field_2: t('失败'),
        field_3: t('成功'),
      },
      {
        type: 'custom',
        slotName: 'invoice_type',
        field_1: t('工资'),
        field_2: t('报销'),
        field_3: 'credit note',
        field_4: t('加班'),
        field_5: t('CC报销'),
      },
      {
        type: 'custom',
        slotName: 'status',
        field_1: t('新创建'),
        field_2: t('已发送'),
        field_3: t('已确认'),
        field_4: t('生成PDF'),
      },
      {
        type: 'color',
        slotName: 'email_record__status',
        cb: (record: any) => {
          if (record.email_record__status == 0 || record.email_record__status == 1) {
            return 'font-weight: bolder';
          } else if (record.email_record__status == 2) {
            return 'color: #FF0000;font-weight: bolder';
          }
        },
        content: (record: any) => {
          if (record.email_record__status == 1) {
            return t('发送成功');
          } else if (record.email_record__status == 2) {
            return t('发送失败');
          } else if (record.email_record__status == 0) {
            return t('已发送');
          }
        },
      },
      {
        type: 'click',
        slotName: 'files_json',
        content: (record: any) => {
          if (record.files_json) {
            return record.files_json?.name;
          } else {
            return '';
          }
        },
        cb: (record: any) => {
          if (record.files_json && record.files_json.file_url) {
            const url: string =
              'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
              encodeURIComponent(record.files_json.file_url);
            window.open(url, '_blank');
            // axios.get('/' + record.files_json.file_url,{
            //   responseType: 'blob' }).then((res: any) => {
            //   var blob = res.data;
            //   // FileReader主要用于将文件内容读入内存
            //   var reader = new FileReader();
            //   reader.readAsDataURL(blob);
            //   // onload当读取操作成功完成时调用
            //   reader.onload = (e: any) => {
            //     var a = document.createElement('a');
            //     // 获取文件名fileName
            //     var fileName = res.config["url"].split("/");
            //     fileName = fileName[fileName.length - 1];
            //     // fileName = fileName.replace(/"/g, "");
            //     a.download = fileName;
            //     a.href = e.target.result;
            //     document.body.appendChild(a);
            //     a.click();
            //     document.body.removeChild(a);
            //   }
            // })
          } else {
            notification.error({
              message: t('提示'),
              description: t('无对应附件'),
            });
          }
        },
      },
      {
        type: 'click',
        slotName: 'excel_files_json',
        content: (record: any) => {
          if (record.excel_files_json) {
            return record.excel_files_json?.name;
          } else {
            return '';
          }
        },
        cb: (record: any) => {
          if (record.excel_files_json && record.excel_files_json.file_url) {
            const url: string =
              'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
              encodeURIComponent(record.excel_files_json.file_url);
            window.open(url, '_blank');
            // axios
            //   .get('/' + record.excel_files_json.file_url, {
            //     responseType: 'blob',
            //   })
            //   .then((res: any) => {
            //     var blob = res.data;
            //     // FileReader主要用于将文件内容读入内存
            //     var reader = new FileReader();
            //     reader.readAsDataURL(blob);
            //     // onload当读取操作成功完成时调用
            //     reader.onload = (e: any) => {
            //       var a = document.createElement('a');
            //       // 获取文件名fileName
            //       var fileName = res.config['url'].split('/');
            //       fileName = fileName[fileName.length - 1];
            //       // fileName = fileName.replace(/"/g, "");
            //       a.download = fileName;
            //       a.href = e.target.result;
            //       document.body.appendChild(a);
            //       a.click();
            //       document.body.removeChild(a);
            //     };
            //   });
          } else {
            notification.error({
              message: t('提示'),
              description: t('无对应附件'),
            });
          }
        },
      },
    ];
    // 选择功能
    const rowSelection = {
      type: 'radio', // 单选
      columnWidth: '30px',
      selectedRowKeys: modalRef.selectedRowsKey,
      onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
        loading.value = false;
        modalRef.selectedRowsKey.length = 0;
        selectedRowKeys.forEach((item: any) => {
          if (item >= 1) {
            modalRef.selectedRowsKey.push(item);
          }
        });
        modalRef.selectedData = selectedRows;
      },
    };
    if (invoice_obj.remittance_list.length >= 1) {
      invoice_obj.remittance_list.forEach((item: any) => {
        item.is_remittance = false;
        item.invoice_list.forEach((item1: any) => {
          item1.is_invoice = false;
          item1.loading1 = false;
          if (Number(item.amount.toFixed(0)) > Number(item1.total_amount.toFixed(0))) {
            item1.color = 'color: #00BBFF;font-weight: bolder';
          } else if (Number(item.amount.toFixed(0)) == Number(item1.total_amount.toFixed(0))) {
            item1.color = 'color: #00DD00;font-weight: bolder';
          } else if (Number(item.amount.toFixed(0)) < Number(item1.total_amount.toFixed(0))) {
            item1.color = 'color: #FF0000;font-weight: bolder';
          }
        });
      });
    }

    // 创建 汇款凭证项
    const create_remittance_pro = (obj: any, item: any) => {
      item.loading1 = true;
      create_remittance({
        org_id: current_org?.id,
        remittance_set_id: invoice_obj.id,
        amount: obj.amount,
        invoice_id: item.id,
      })
        .then((res: any) => {
          item.invoice_id = item.id;
          item.is_create = true;
          obj.is_remittance = true;
          obj.is_invoice = true;
          item.status = null;
          Object.assign(item, res);
          item.id = item.invoice_id;
          message.success(t('汇款凭证项创建成功'));
          obj.invoice_list.length = 0;
          obj.invoice_list.push(item);
          emit('ok', invoice_obj);
          // update_remittance_set({
          //   org_id: current_org?.id,
          //   remittance_set_id: item.remittance_set_id,
          //   description: invoice_obj.description + item.invoice_no + ':' + item.amount + ';',
          // }).then((res1: any) => {
          //   invoice_obj.description = res1.description;
          // });
        })
        .finally(() => {
          item.loading1 = false;
        });
    };

    const goto = (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-todoevent',
        query: { adviser_id: record.adviser_id, t: new Date().getTime() },
      });
    };
    watchEffect(() => {
      let num1 = 0;
      let num2 = 0;
      let num3 = 0;
      invoice_obj.remittance_list.forEach((item: any) => {
        item.invoice_list.forEach((item1: any) => {
          if (item1.status === 1) {
            num1++;
          } else if (item1.status === 2) {
            num1++;
            num2++;
            num3++;
          } else if (item1.status === 4) {
            num1++;
            num3++;
          }
        });
      });
      invoice_obj.payslip_num = num1;
      invoice_obj.sted_num = num2;
      invoice_obj.pdf_num = num3;
    });

    const getDateTime = (time: any) => {
      if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const getMonth = (time: any) => {
      if (time) {
        time = time.substring(0, 7);
      }
      return time;
    };
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };

    const handleSubmit = (index: any) => {
      invoice_obj.remittance_list.forEach((item1: any) => {
        if (item1.index == index) {
          let srt: any = '';
          if (
            Number(item1.amount.toFixed(0)) >
            Number(modalRef.selectedData[0].total_amount.toFixed(0))
          ) {
            srt = 'color: #00BBFF;font-weight: bolder';
          } else if (
            Number(item1.amount.toFixed(0)) ==
            Number(modalRef.selectedData[0].total_amount.toFixed(0))
          ) {
            srt = 'color: #00DD00;font-weight: bolder';
          } else if (
            Number(item1.amount.toFixed(0)) <
            Number(modalRef.selectedData[0].total_amount.toFixed(0))
          ) {
            srt = 'color: #FF0000;font-weight: bolder';
          }
          item1.invoice_list.reverse();
          item1.invoice_list.push({
            ...modalRef.selectedData[0],
            is_invoice: false,
            loading1: false,
            color: srt,
          });
          item1.invoice_list.reverse();
        }
      });
      modalRef.visible = false;
      modalRef.selectedRowsKey.length = 0;
    };

    const open_pdf = (file_url: any) => {
      if (file_url) {
        window.open('https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + file_url, '_blank');
        // axios.get('/' + file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    };

    // 批量创建工资单
    const create_remittance_list = () => {
      const list: any = [];
      let is_create = false;
      let amount_sum = 0;
      let invoice_sum = 0;
      console.log('123', invoice_obj.remittance_list);
      const temp_data = invoice_obj.remittance_list.filter((item: any) => {
        return item.checked;
      });
      temp_data.forEach((item: any) => {
        if (item.invoice_list.length === 0 || item.invoice_list.length > 1) {
          message.error(item.adviser + t('未选择，或选择多个'));
          is_create = true;
        }
        if (item.invoice_list.length === 1) {
          amount_sum = amount_sum + item.amount;
          item.invoice_list.forEach((item1: any) => {
            invoice_sum = invoice_sum + item1.total_amount;
          });
        }
      });
      if (is_create) {
        return '';
      }
      if (amount_sum != invoice_sum) {
        Modal.confirm({
          title: t('汇款凭证项金额之和与选中的请款单总金额之和有出入，是否继续'),
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              temp_data.forEach((item: any) => {
                console.log('item', item);
                if (item.invoice_list[0].contract__is_double_upload) {
                  list.push({
                    amount: item.amount,
                    invoice_id: Number(item.invoice_list[0].id),
                    remittance_set_id: Number(invoice_obj.id),
                  });
                } else {
                  notification.error({
                    message: t('温馨提示'),
                    description: item.invoice_list[0].invoice_no + t('双方合同没有确认上传') + '!',
                  });
                }
              });
              bulk_create_remittance({
                org_id: current_org.id,
                data_json_list: JSON.stringify(list),
              })
                .then((res: any) => {
                  message.success(res.message);
                  const invoice_ids: any = [];
                  list.map((item: any) => {
                    invoice_ids.push(item.invoice_id);
                  });
                  if (invoice_ids.length > 0) {
                    bulk_create_payslip({
                      org_id: current_org.id,
                      invoice_ids: invoice_ids,
                    }).then((res: any) => {
                      message.success(t('工资单批量创建成功'));
                      res.data.map((item: any) => {
                        payslip__ids.push(item.id);
                      });
                      query_payslip_list({
                        org_id: current_org?.id,
                        ids: payslip__ids,
                      }).then((res: any) => {
                        dataSource.value = res.data;
                      });
                      handleBulkCreatePayslipAfter(res.data);
                    });
                  } else {
                    notification.error({
                      message: t('提示'),
                      description: t('无对应请款单，无法创建'),
                    });
                  }
                })
                .finally(() => {
                  console.error(1);
                  invoice_obj.remittance_list.forEach((item: any) => {
                    //选中框恢复`未选中`状态
                    item.checked = false;
                  });
                  resolve(null);
                });
            }).catch(() => console.log('Oops errors!'));
          },
          onCancel() {},
        });
      } else {
        temp_data.forEach((item: any) => {
          if (item.invoice_list[0].contract__is_double_upload) {
            list.push({
              amount: item.amount,
              invoice_id: Number(item.invoice_list[0].id),
              remittance_set_id: Number(invoice_obj.id),
            });
          } else {
            notification.error({
              message: t('温馨提示'),
              description: item.invoice_list[0].invoice_no + t('双方合同没有确认上传') + '!',
            });
          }
        });
        bulk_create_remittance({
          org_id: current_org.id,
          data_json_list: JSON.stringify(list),
        })
          .then((res: any) => {
            message.success(res.message);
            const invoice_ids: any = [];
            list.map((item: any) => {
              invoice_ids.push(item.invoice_id);
            });
            if (invoice_ids.length > 0) {
              bulk_create_payslip({
                org_id: current_org.id,
                invoice_ids: invoice_ids,
              }).then((res: any) => {
                message.success(t('工资单批量创建成功'));
                res.data.map((item: any) => {
                  payslip__ids.push(item.id);
                });
                query_payslip_list({
                  org_id: current_org?.id,
                  ids: payslip__ids,
                }).then((res: any) => {
                  dataSource.value = res.data;
                });
                handleBulkCreatePayslipAfter(res.data);
              });
            } else {
              notification.error({
                message: t('提示'),
                description: t('无对应请款单，无法创建'),
              });
            }
          })
          .finally(() => {
            invoice_obj.remittance_list.forEach((item: any) => {
              //选中框恢复`未选中`状态
              item.checked = false;
              item.is_payslip = true;
              item.is_create = true;
            });
          });
      }
    };
    const refresh1 = () => {
      query_payslip_list({
        org_id: current_org?.id,
        ids: payslip__ids,
      }).then((res: any) => {
        dataSource.value = res.data;
        let is_refresh = false;
        res.data.map((item1: any) => {
          if (item1.pdf_status == 2) {
            message.error(item1.payslip_no + t('PDF生成失败'));
          }
          if (item1.status == 1 && item1.pdf_status == 1) {
            is_refresh = true;
          }
        });
        if (is_refresh) {
          setTimeout(() => {
            refresh1();
          }, 1000);
        }
      });
    };
    const refresh2 = () => {
      query_payslip_list({
        org_id: current_org?.id,
        ids: payslip__ids,
      }).then((res: any) => {
        dataSource.value = res.data;
        let is_refresh = false;
        res.data.map((item1: any) => {
          if (item1.email_record__status == 2) {
            message.error(item1.payslip_no + t('PDF生成失败'));
          }
          if (item1.email_record__status == 0) {
            is_refresh = true;
          }
        });
        if (is_refresh) {
          setTimeout(() => {
            refresh2();
          }, 1000);
        }
      });
    };
    const create_pdf = () => {
      Modal.confirm({
        title: t('确定生成PDF') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_create_payslip_pdf({
              org_id: current_org?.id,
              payslip_ids: payslip__ids,
            })
              .then((res: any) => {
                message.success(t('工资单批量生成PDF成功'));
                refresh1();
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    };
    const send__email = () => {
      Modal.confirm({
        title: t('确定批量发送邮件') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            bulk_send_payslip_by_email({
              org_id: current_org.id,
              payslip_ids: payslip__ids,
            })
              .then(() => {
                message.success(t('批量发送邮件成功'));
                refresh2();
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    };
    const batch_create_pdf = () => {
      const payslip_ids: any = [];
      dataSource.value.map((item: any) => {
        payslip_ids.push(item.id);
      });
      bulk_create_payslip_pdf({
        org_id: current_org.id,
        payslip_ids: payslip_ids,
      }).then(() => {
        message.success(t('PDF生成成功'));
        query_payslip(payslip_ids);
      });
    };
    const batch_create_email = () => {
      const payslip_ids: any = [];
      dataSource.value.map((item: any) => {
        payslip_ids.push(item.id);
      });
      bulk_send_payslip_by_email({
        org_id: current_org.id,
        payslip_ids: payslip_ids,
      }).then(() => {
        message.success(t('邮件发送成功'));
        query_payslip1(payslip_ids);
      });
    };
    const refresh = () => {
      const ids: any = [];
      dataSource.value.map((item: any) => {
        ids.push(item.id);
      });
      query_payslip_list({
        org_id: current_org?.id,
        ids: ids,
      }).then((res: any) => {
        dataSource.value = res.data;
      });
    };
    const update_pay = (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PayslipModal',
        type: 'update',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        query_payslip_list({
          org_id: current_org?.id,
          ids: [record.id],
        }).then((res: any) => {
          Object.assign(record, res.data[0]);
        });
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    };
    const query_payslip = (ids: any) => {
      query_payslip_list({
        org_id: current_org?.id,
        ids: ids,
      }).then((res: any) => {
        dataSource.value = res.data;
        let is_pdf = false;
        res.data.map((item: any) => {
          if (item.status != 4) {
            is_pdf = true;
          }
        });
        if (is_pdf) {
          setTimeout(() => {
            query_payslip(ids);
          }, 1000);
        }
      });
    };
    const query_payslip1 = (ids: any) => {
      query_payslip_list({
        org_id: current_org?.id,
        ids: ids,
      }).then((res: any) => {
        dataSource.value = res.data;
        let is_pdf = false;
        res.data.map((item: any) => {
          if (item.email_record__status != 1) {
            is_pdf = true;
          }
        });
        if (is_pdf) {
          setTimeout(() => {
            query_payslip1(ids);
          }, 1000);
        }
      });
    };
    const preview_pay = (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PreviewModal',
        type: 'payslip',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modalBox(modifyModal);
    };

    const del_pay = (record: any) => {
      Modal.confirm({
        title: t('是否删除工资单') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            delete_payslip({
              org_id: current_org?.id,
              payslip_id: record.id,
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    };
    const recover_pay = (record: any) => {
      recover_payslip({
        org_id: current_org?.id,
        payslip_id: record.id,
      }).then((res: any) => {
        message.success(t('恢复成功'));
        Object.assign(record, res);
      });
    };
    const mangefee_pay = (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改工资单管理费'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'management_fee',
              label: '管理费',
              label_i18n: '管理费',
              placeholder_i18n: '请填写管理费',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写管理费',
            },
          ],
          rules: {
            management_fee: [{ required: true, message: t('请填写管理费描述') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            payslip_id: record.id,
            management_fee: record.management_fee,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            modify_payslip_mangefee(payload)
              .then((res: any) => {
                message.success(t('修改工资单管理费成功'));
                query_payslip_list({
                  org_id: current_org?.id,
                  ids: [record.id],
                }).then((res: any) => {
                  Object.assign(record, res.data[0]);
                });
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    };
    const del_invoice = (obj: any, item: any) => {
      obj.invoice_list.map((res: any, index: any) => {
        if (res.id == item.id) {
          obj.invoice_list.splice(index, 1);
        }
      });
    };

    const handleBulkCreatePayslipAfter = (resData: any[]) => {
      if (resData && resData.length > 0) {
        invoice_obj.remittance_list.forEach((item: any) => {
          item.invoice_list.forEach((obj: any) => {
            const f = resData.find((temp: any) => {
              return temp.invoice_id == obj.id;
            });
            if (f) {
              f.is_payslip = true;
              f.is_create = true;
              obj.invoice_id = item.id;
              obj.is_create = true;
              item.is_remittance = true;
              item.is_invoice = true;
              item.status = null;
              Object.assign(obj, f);
            }
          });
        });
      }
    };
    const checkDisabledFun = () => {
      let disabled = false;
      const f_list = invoice_obj.remittance_list.filter((item: any) => {
        return item.checked;
      });
      if (f_list.length > 0) {
        f_list.forEach((item: any) => {
          if (item.invoice_list.length === 0 || item.invoice_list.length > 1) {
            disabled = true;
          }
          item.invoice_list.forEach((obj: any) => {
            if (obj.is_create) {
              disabled = true;
            }
          });
        });
      } else {
        disabled = false;
      }
      return disabled;
    };
    const all_checked = () => {
      if (checked_all.value) {
        invoice_obj.remittance_list.forEach((item: any) => {
          //选中框恢复`未选中`状态
          item.checked = false;
        });
      } else {
        invoice_obj.remittance_list.forEach((item: any) => {
          //选中框恢复`未选中`状态
          item.checked = true;
        });
      }
    };
    return {
      t,
      searchConfig,
      searchConfigOptions,
      slotList,
      dynamicColumns,
      columnState,
      rowSelection,
      state,
      search_modelRef,
      modalRef,
      loading,
      btn_loading,
      checked_all,
      invoice_obj,
      searchValidateInfos,
      searchResetFields,
      payslip_loading,
      dataSource,
      all_checked,
      getDateTime,
      // btn_create_bank,
      btn_create_pdf,
      btn_create_send,
      btn_contract,
      btn_update_payslip,
      btn_refresh_payslip,
      btn_create_payslip,
      btn_manage_payslip,
      search,
      goto,
      open_pdf,
      handleTableChange,
      create_remittance_pro,
      handleSubmit,
      getMonth,
      batch_create_email,
      getDate,
      Columns,
      preview_pay,
      batch_create_pdf,
      update_pay,
      mangefee_pay,
      del_pay,
      del_invoice,
      slotList1,
      recover_pay,
      refresh,
      create_remittance_list,
      create_pdf,
      send__email,
      checkDisabledFun,
    };
  },
});
</script>
