
import { defineComponent, onMounted, reactive, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { import_remittance } from '@/api/cerp-model';
import { useStore } from 'vuex';
import { btn_update_adviser } from '@/views/profile/basic/basic.ts';
import ShowRemittanceSet from '@/components/show-remittanceset/index.vue';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const store = useStore();
    const tableHeight = reactive({ y: window.innerHeight - 210 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 210;
      };
    });
    route.meta.title = Number(route.params['id']) + '# ' + t('汇款凭证项批量创建');
    watchEffect(() => {
      if (route.path.split('/')[2] == 'basic-remittanceset') {
        route.meta.title = Number(route.params['id']) + '# ' + t('汇款凭证批量创建');
      }
    });
    const remittance_obj: any = reactive({
      remittance_name: route.params['name'],
      date_received: route.params['date'],
      total_amount: route.params['total_amount'],
      id: route.params['invoice_id'],
    });
    const current_org = store.getters['user/current_org'];
    const dataList: any = ref([]);
    const btn_loading: any = ref(false);
    const btn_true: any = ref(false);
    import_remittance({
      nsfile_id: Number(route.params['id']),
      org_id: current_org?.id,
    }).then((res: any) => {
      remittance_obj.remittance_list = res;
      dataList.value.push(remittance_obj);
      dataList.value.forEach((item: any) => {
        item.is_create = false;
        item.remittance_list.forEach((item1: any) => {
          item1.is_remittance = false;
          item1.invoice_list.forEach((item2: any) => {
            item2.is_invoice = false;
          });
        });
      });
      console.log('dataList', dataList.value);
    });

    const is_create_number = (obj: any) => {
      let bel = false;
      console.log('obj1', obj);
      for (let i: any = 0; i < obj.remittance_list.length; i++) {
        if (obj.remittance_list[i].is_remittance) {
          bel = true;
        } else {
          bel = false;
          break;
        }
      }
      if (bel) {
        obj.is_create = true;
      }
    };
    // const create_number: any = computed(() => {
    //   let num = 0;
    //   dataList.value.forEach((item: any) => {
    //     if (item.is_create) {
    //       num = num + 1;
    //     }
    //   });
    //   return num;
    // });
    const getDate = (time: any) => {
      if (time) {
        time = time.substring(0, 10);
      }
      return time;
    };
    return {
      t,
      dataList,
      // create_number,
      btn_true,
      tableHeight,
      btn_loading,
      getDate,
      is_create_number,
      btn_update_adviser,
      remittance_obj,
    };
  },
  components: {
    ShowRemittanceSet,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  },
});
